export default () => ({
  postUrl: "",
  responses: ["", "", ""],
  productId: "",
  etiquetaId: "",
  fanpageId: "",
  type: "VARIOS_PRODUCTOS",
  postImgUrl: "",
  selectedUrl: "",
  platform: "",
});
